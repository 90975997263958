import React from "react";
import theme from "theme";
import { Theme, Text, Strong, Link, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				ExpertPathway
			</title>
			<meta name={"description"} content={"Ваш надійний партнер у ремонті велосипедів та мотоциклів"} />
			<meta property={"og:title"} content={"ExpertPathway"} />
			<meta property={"og:description"} content={"Ваш надійний партнер у ремонті велосипедів та мотоциклів"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6744384d9335410018c81ed7/images/repair-gear-with-wrench-and-bike-free-vector.jpg?v=2024-11-25T13:59:53.754Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6744384d9335410018c81ed7/images/repair-gear-with-wrench-and-bike-free-vector.jpg?v=2024-11-25T13:59:53.754Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6744384d9335410018c81ed7/images/repair-gear-with-wrench-and-bike-free-vector.jpg?v=2024-11-25T13:59:53.754Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6744384d9335410018c81ed7/images/repair-gear-with-wrench-and-bike-free-vector.jpg?v=2024-11-25T13:59:53.754Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6744384d9335410018c81ed7/images/repair-gear-with-wrench-and-bike-free-vector.jpg?v=2024-11-25T13:59:53.754Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6744384d9335410018c81ed7/images/repair-gear-with-wrench-and-bike-free-vector.jpg?v=2024-11-25T13:59:53.754Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6744384d9335410018c81ed7/images/repair-gear-with-wrench-and-bike-free-vector.jpg?v=2024-11-25T13:59:53.754Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="text">
				ExpertPathway
			</Override>
		</Components.Header>
		<Section padding="112px 0 0px 0" background="--color-darkL2" md-padding="96px 0 0px 0" sm-padding="72px 0 0px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				margin="0px 0px 40px 0px"
				padding="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					width="90%"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					sm-width="100%"
				>
					Ваш надійний партнер у ремонті велосипедів та мотоциклів
				</Text>
				<Text
					margin="0px 0px 32px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					text-align="center"
					width="80%"
				>
					Ласкаво просимо до{" "}
					<Strong>
						ExpertPathway24.live
					</Strong>
					{" "}— місця, де ваші велосипеди та мотоцикли отримують нове життя! Ми спеціалізуємося на професійному ремонті, технічному обслуговуванні та модернізації двоколісного транспорту. Наша мета — забезпечити вам комфорт, безпеку та насолоду від їзди.
				</Text>
				<Link
					href="/poslugi-ta-cini"
					padding="12px 24px 12px 24px"
					color="--dark"
					background="--color-secondary"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 0px 0px 0px"
					sm-margin="0px 0px 0px 0px"
					sm-text-align="center"
					hover-transition="background-color 0.2s linear 0s"
					hover-background="--color-orange"
					transition="background-color 0.2s linear 0s"
				>
					Наші послуги
				</Link>
			</Box>
			<Box
				display="flex"
				width="100%"
				justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
			/>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Box
				padding="80px 72px 80px 72px"
				background="linear-gradient(270deg,rgba(0,0,0,0) 0%,rgba(248, 248, 248, 0.99) 100%),rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6744384d9335410018c81ed7/images/Hoffy-Cycles.jpg?v=2024-11-25T13:59:53.772Z) 0% 0% /cover no-repeat scroll padding-box"
				border-radius="24px"
				margin="0px 0px 64px 0px"
				sm-padding="60px 36px 60px 36px"
			>
				<Box
					width="60%"
					display="flex"
					flex-direction="column"
					md-width="100%"
					md-align-items="center"
					md-justify-content="center"
					md-text-align="center"
					lg-width="100%"
				>
					<Text
						margin="0px 0px 16px 0px"
						font="--headline2"
						color="--dark"
						md-text-align="center"
						sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						<Strong>
							Чому обирають нас?
						</Strong>
					</Text>
				</Box>
			</Box>
			<Box
				display="grid"
				lg-flex-wrap="wrap"
				grid-template-columns="repeat(4, 1fr)"
				grid-gap="36px 4%"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				sm-grid-gap="12px"
			>
				<Box display="flex" flex-direction="column" sm-margin="0px 0px 30px 0px" sm-width="100%">
					<Text
						margin="0px 0px 18px 0px"
						color="--darkL2"
						font="--headline3"
						lg-text-align="left"
						text-transform="uppercase"
					>
						Досвід та експертиза.
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
						Наша команда має понад 10 років досвіду в ремонті велосипедів та мотоциклів. Ми знаємо все про двоколісний транспорт, незалежно від марки чи моделі.
						<br />
						<br />
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-width="100%" sm-margin="0px 0px 30px 0px">
					<Text
						margin="0px 0px 18px 0px"
						color="--darkL2"
						font="--headline3"
						lg-text-align="left"
						text-transform="uppercase"
					>
						Індивідуальний підхід.
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
						Кожен клієнт для нас важливий, тому ми пропонуємо персоналізовані рішення, які відповідають вашим потребам і бюджету.
						<br />
						<br />
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-margin="0px 0px 30px 0px" sm-width="100%">
					<Text
						margin="0px 0px 18px 0px"
						color="--darkL2"
						font="--headline3"
						lg-text-align="left"
						text-transform="uppercase"
					>
						Сучасне обладнання.
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
						Ми використовуємо лише професійні інструменти та сертифіковані деталі, що гарантує високу якість робіт.
						<br />
						<br />
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-width="100%" sm-margin="0px 0px 0px 0px">
					<Text
						margin="0px 0px 18px 0px"
						color="--darkL2"
						font="--headline3"
						lg-text-align="left"
						text-transform="uppercase"
					>
						Доступні ціни.
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
						Ми пропонуємо конкурентоспроможні ціни на всі види послуг, зберігаючи високу якість обслуговування.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Steps />
		<Components.Cta2 />
		<Components.Footer>
			<Override slot="link7" />
			<Override slot="link" />
			<Override slot="text3" />
			<Override slot="text2" />
			<Override slot="link5" />
			<Override slot="link6" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"673cdfe29710450018a47394"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}\nhtml, body {\nscroll-behavior: smooth;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});